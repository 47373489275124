.sidebar-shadow {
  box-shadow: 1px 0px 1px rgb(0 0 0 / 9%), 2px 0px 1px rgb(0 0 0 / 9%),
    4px 0px 2px rgb(0 0 0 / 9%), 6px 0px 3px rgb(0 0 0 / 9%),
    10px 0px 5px rgb(0 0 0 / 9%), 16px 0px 8px rgb(0 0 0 / 9%) !important;
}

.headerbar-shadow {
  box-shadow: 0 1px 1px rgb(0 0 0 / 8%), 0 2px 2px rgb(0 0 0 / 12%),
    0 4px 4px rgb(0 0 0 / 16%), 0 8px 8px rgb(0 0 0 / 20%) !important;
}

.activeLink {
  position: relative;
}
.activeLink::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 5px;
  background-color: var(--clr-brr);
  animation: fade-in 300ms;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
