.profile-dialog-content__header {
  padding-left: max(75px, 1rem) !important;
  padding-right: max(75px, 1rem) !important;
  padding-bottom: 40px !important;
}

.profile-dialog-content__body {
  padding-top: 0 !important;
  padding-left: max(75px, 1rem) !important;
  padding-right: max(75px, 1rem) !important;
  padding-bottom: 50px !important;
}
