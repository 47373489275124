:root {
  --ff-primary: 'Roboto', sans-serif;
  --fw-primary: 400;
  --fw-bold: 700;
  --fs-base: 1rem;
  --fs-event:0.8rem;
  --fs-message: clamp(1.125rem, calc(0.9194rem + 0.3582vw), 1.2rem);
  --fs-message-note: clamp(1rem, calc(0.9194rem + 0.3582vw), 1.125rem);

  --clr-brr: hsl(12, 94%, 63%);
  --clr-gray80: hsl(0, 0%, 20%);
  --clr-gray60: hsl(0, 0%, 40%);
  --clr-gray40: hsl(0, 0%, 60%);
  --clr-gray30: hsl(0, 0%, 70%);
  --clr-gray20: hsl(0, 0%, 80%);
  --clr-gray10: hsl(0, 0%, 90%);
  --clr-w: hsl(0, 0%, 100%);
  --clr-btn-cta: hsl(121, 38%, 47%);
  --clr-btn-cta-hover: hsl(126, 57%, 34%);
  --clr-success-dark: hsl(79, 80%, 38%);
  --clr-success: hsl(79, 85%, 45%);
  --clr-success60: hsl(79, 85%, 40%);
  --clr-success-dark: hsl(88, 51%, 20%);
  --clr-danger: hsl(0, 65%, 51%);
  --clr-danger60: hsl(0, 65%, 40%);
  --clr-warning: hsl(54, 87%, 50%);
  --clr-warning60: hsl(54, 87%, 40%);
  --clr-warning80: hsl(55, 100%, 18%);
  --clr-info: hsl(209, 86%, 55%);
  --clr-info40: hsl(199, 92%, 56%);
  --clr-info60: hsl(207, 100%, 40%);

  --clr-primary: #1976d2;

  --layout-container-min-width: 650px;
  --sidebar-width: 75px;
  --topbar-height: 60px;

  --question-list-container-max-witdh: 1200px;

  --toastify-color-success: blue;

}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--clr-w);
  font-family: var(--ff-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  font-family: var(--ff-original);
}

a {
  text-decoration: none;
  line-height: 1;
  font-size: 1rem;
}

a:visited,
a:hover,
a:active {
  color: inherit;
}

.disabled-link {
  pointer-events: none;
}

.MuiTabs-flexContainer button.Mui-selected {
  color: var(--clr-gray80) !important;
}

.MuiTabs-indicator {
  height: 3px !important;
  background-color: var(--clr-gray60) !important;
}

.message-content-editable {
  /* max-width: 85ch; */
  /* width: max(600px, 20vw); */
  width: max(450px, 100%);
  padding: 0.25rem 0.5rem;
  font-family: inherit;
  /* font-size: var(--fs-event); */
  color: inherit;
  cursor: pointer;
  margin-left: 1.25rem;
}

.message-content-editable:focus {
  cursor: text;
  outline-color: var(--clr-gray60);
}

.black-background {
  background-color: black;
}
.App {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-bubble {
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #1976d2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.2s;
}

.chat-bubble.open {
  transform: scale(1.1);
}

.chat-messages {
  position: absolute;
  top: 20;
  left: 20px;
  padding: 16px;
  /* max-width: 250px; */
  width: 60vw;
  height: auto;
  background-color: #fff;
}
