.fade-in-box {
  animation: fadein 1s;
  -moz-animation: fadein 1s; /* Firefox */
  -webkit-animation: fadein 1s; /* Safari and Chrome */
  -o-animation: fadein 1s; /* Opera */
}

.new-message-indicator {
  opacity: 0;
  animation: fadein 1500ms forwards;
  -moz-animation: fadein 1500ms forwards;
  -webkit-animation: fadein 1500ms forwards;
  -o-animation: fadein 1500ms forwards;
}

/* 
  >>>>>>>>>>>>> @change q&a toolbar position
*/
.question-toolbar-position {
  position: sticky;
  top: 60px;
  background-color: var(--clr-w);
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.11),
    0 4px 4px -2px rgba(0, 0, 0, 0.11), 0 8px 8px -4px rgba(0, 0, 0, 0.11);
  z-index: 1000;
}

/* 
  >>>>>>>>>>>>> @fadein
*/
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* 
  >>>>>>>>>>>>> @new message
*/
/* @keyframes new-message-indicator {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes new-message-indicator {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes new-message-indicator {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes new-message-indicator {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} */
