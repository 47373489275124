.thank-you_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #333;
  color: #ffffff;
}

.thank-you_header {
  align-self: flex-start;
  padding-top: min(25vh, 3rem);
  padding-left: min(20vw, 3rem);
}

.thank-you_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-grow: 1;
  width: max(20vw, 35vw);
}

.thank-you_content_btns-container {
  padding: 1.5rem;
}
.thank-you_content_btns-container button:first-child {
  margin-right: 2rem;
}
.thank-you_content_btns-container button {
  padding: 1rem 1rem;
  width: max(140px, 'auto');
  border-color: var(--clr-w);
}

.thank-you_content_btns-container button:hover,
.thank-you_content_btns-container button:focus {
  border-color: var(--clr-gray40);
}

.thank-you_content_btns-container
  button:hover
  a
  .thank-you_content_btns-container
  button:focus
  a {
  color: var(--clr-gray40);
}

.thank-you_content_btns-container button a {
  color: var(--clr-w);
  text-decoration: none;
  transition: color 250ms;
}

.thank-you_footer {
  display: flex;
  padding-bottom: 1rem;
}
.thank-you_footer > * {
  font-size: 3rem !important;
}
.thank-you_footer > svg:nth-child(2) {
  margin: 0 1rem;
}
