.desktop-layout {
  display: grid;
  grid-template-columns: 1fr 2fr;
  height: 100vh;
  overflow-y: hidden;
}

.desktop-layout__left-panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 50ch;
  max-width: 75ch;
  max-height: 100%;
  margin: 0 auto;
  padding-block: max(10vh, 2rem);
  padding-left: 3rem;
  padding-right: 3rem;
  overflow: hidden;
}

.tablet-layout-portrait {
  display: none;
}

.auth-form-container > div {
  min-height: 100vh;
}

.auth-form-container > div > div.login-container {
  align-items: center;
}

.auth-form-container > div > div.register-container__form {
  margin: 0 auto;
}

.login-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: max(4vw, 2.5rem);
  padding-bottom: 2.5rem;
  padding-left: max(4vw, 2.5rem);
}

.login__form-container {
  /* width: max(45vw, 30ch); */
  width: 100%;
  text-align: center;
}

.login__form-group {
  margin-top: 1.5rem !important;
}

.register-container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.register-container__form {
  flex-grow: 1;
  padding-top: max(12vh, 4rem);
  padding-right: max(4vw, 3rem);
  padding-bottom: 5rem;
  padding-left: max(4vw, 3rem);
}
