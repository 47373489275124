.main404 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background-image: url(../../static/img/not-found-francesco-ungaro.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff;
  filter: brightness(1.7);
}

.main404__background-pattern {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAG0lEQVQYV2NkYGD4z8DAwMgABXAGNgGwSgwVAFbmAgXQdISfAAAAAElFTkSuQmCC)
    repeat;
}

.main404__content {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.main404__content h1 {
  font-size: clamp(8rem, 10vw, 10rem);
  font-weight: 900;
  line-height: 1;
}

.main404__content h1:nth-of-type(2) {
  font-size: clamp(2.5rem, 3vw, 3.15rem);
  white-space: nowrap;
}

.main404__content h4 {
  margin-top: max(3rem, 8vh);
  margin-bottom: 2rem;
  font-size: clamp(1.75rem, 3vw, 2.15rem);
}

.main404__content a {
  width: min(100%, 29ch);
  /* width: clamp(13rem, 20vw, 16rem); */
  background: var(--clr-info60);
  text-transform: capitalize;
  font-size: 1rem;
}

.main404__content a:hover {
  background-color: var(--clr-gray80);
}
