.center-items {
  align-items: center;
}

.portrait-text-description {
  max-width: 75ch;
}

.portrait-text-description p {
  text-align: center;
  margin-top: 1rem;
}

.portrait-text-description p:last-child {
  margin-top: 3rem;
}

.portrait-text-description > div {
  justify-content: center;
}

.portrait-text-description > div > * {
  color: var(--clr-w);
}

.input-auth > div {
  overflow: hidden;
  background-color: var(--clr-w);
}

.shadow-auth-section {
  box-shadow: -15px 0 30px rgba(0, 0, 0, 0.3), -10px 0 20px rgba(0, 0, 0, 0.3),
    -5px 0 7px rgba(0, 0, 0, 0.2);
}

.cookie-container {
  position: absolute;
  left: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;

  margin-left: 1rem;
  margin-bottom: 2rem;
  padding: 3rem 1.5rem;
  border-radius: 8px;
  background: var(--clr-w);
  color: var(--clr-gray80);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.12),
    0 2px 2px rgba(0, 0, 0, 0.12), 0 4px 4px rgba(0, 0, 0, 0.12),
    0 8px 8px rgba(0, 0, 0, 0.12), 0 16px 16px rgba(0, 0, 0, 0.12);
  z-index: 999;
  overflow: hidden;
}

.cookie-content::before {
  content: '';
  position: absolute;
  background-image: url(../../static/icons/cookie_black_24dp.svg);

  background-repeat: no-repeat;
  background-size: 150%;
  background-position: top -60px left 95%;
  opacity: 0.05;
  top: 0;
  right: 0;
  bottom: 120px;
  left: 0;
  transform: rotate(90deg);
  z-index: -1;
}

.cookie-container div:nth-of-type(2) {
  margin: 2rem auto;
  margin-bottom: 0;
}

.cookie-content div:nth-of-type(1) {
  margin-top: 1.5rem;
}

.cookie-container div button {
  padding: 0.5rem 1.5rem;
  color: var(--clr-w);
  background: var(--clr-info);
}

.cookie-container div button:hover {
  color: var(--clr-w);
  background: var(--clr-info60);
}

.cookie-content a:nth-of-type(1) {
  color: var(--clr-info);
}

/* .cookie-button {
  flex: 0 0 auto;
  padding: 5px 10px;
  margin-top: 2rem;
  margin-bottom: 0;
  padding: 0.5rem 1.5rem;
  color: var(--clr-w);
  background: var(--clr-info);
  border: 0;
  border-radius: 5px;
  box-shadow: none;
  cursor: pointer;
} */
